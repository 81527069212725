import { Component } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'hapr-marketing-site';
  public userEmail = "";
  public hasSignedUp = false;
  public screenshots = [
    '/assets/hapr_reports.PNG',
    '/assets/hapr_impacts.PNG',
    '/assets/hapr_dashboard.PNG'
  ];
  public screenshotIndex = 0;

  constructor(private iconRegistry: MatIconRegistry, private sanitizer: DomSanitizer, private http: HttpClient) {
    this.registerIcons();
  }

  public changeScreenshot(amt){
    this.screenshotIndex += amt;
    if(this.screenshotIndex < 0) this.screenshotIndex = this.screenshots.length-1;
    if(this.screenshotIndex >= this.screenshots.length) this.screenshotIndex = 0;
  }

  public getScreenshot(){
    return this.screenshots[this.screenshotIndex];
  }

  public signup() {
    if (this.userEmail.length > 5) {
      this.hasSignedUp = true;
      let url = "https://api.hapr.io";
      this.http.post(url + "/support/addinterestedpeopleemail", { email: this.userEmail })
        .subscribe((result) => {
          console.log("Email recorded");
        });
    }
  }

  public scrollTo(element) {
    element.scrollIntoView();
  }

  public scrollToTop() {
    window.scrollTo(0, 0);
  }

  public appRedirect() {
    window.location.href = "https://app.hapr.io";
  }

  registerIcons() {
    this.iconRegistry.addSvgIcon(
      'ham-menu',
      this.sanitizer.bypassSecurityTrustResourceUrl('/assets/baseline-menu-24px.svg'));

    this.iconRegistry.addSvgIcon(
      'lock',
      this.sanitizer.bypassSecurityTrustResourceUrl('/assets/baseline-lock-24px.svg'));

    this.iconRegistry.addSvgIcon(
      'customize',
      this.sanitizer.bypassSecurityTrustResourceUrl('/assets/baseline-question_answer-24px.svg'));

    this.iconRegistry.addSvgIcon(
      'chart',
      this.sanitizer.bypassSecurityTrustResourceUrl('/assets/baseline-insert_chart-24px.svg'));

    this.iconRegistry.addSvgIcon(
      'arrow_right',
      this.sanitizer.bypassSecurityTrustResourceUrl('/assets/baseline-arrow_forward-24px.svg'));

    this.iconRegistry.addSvgIcon(
      'arrow_left',
      this.sanitizer.bypassSecurityTrustResourceUrl('/assets/baseline-arrow_back-24px.svg'));

  }
}
